import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SocialIcon from "../components/SocialIcon"
import loadingSpinner from "../../static/143896-user-interface-animation-loop.json"
import HeroSection from "../components/HeroSection"
import Skills from "../components/Skills"
const About = () => {
  return (
    <Layout>
      <Seo title="About" />
      <HeroSection lottiefile={loadingSpinner} title={'About Me'} subtitle={'Building Seamless Experiences through Innovative Front End Solutions'} />
      
      <Skills title={'Skill & Tools'} subtitle={'The skills, tools and technologies I use to bring your products to life'} />

      <div className="w-full max-w-screen-xl mx-auto box relative md:px-8 p-4">
        <div className="grid grid-cols-12 gap-8 md:py-10 py-3">
          <div className="lg:col-span-12 col-span-12 content-between grid">
            <div>
              <p className="md:text-xl mb-5">
                Hi there! My name is Milap Dave, and I'm a front end developer
                with a passion for creativity. In addition to coding, I love
                exploring my interests in photography, and videography, which
                all contribute to my unique perspective and approach to building
                for the web.
              </p>
              <p className="md:text-xl mb-5">
                Building for the web is a fascinating and rewarding experience.
                From a single HTML file to a large React js application, I enjoy
                the process of creating something from scratch and watching it
                come to life.
              </p>
              <p className="md:text-xl mb-5">
                As a developer, I bring my passion and creativity to every
                project. I believe in building intuitive, user-friendly websites
                that offer a seamless experience for visitors. Whether you're
                looking to build a simple website or a complex web application,
                I'm here to help you bring your vision to life. Let's create
                something incredible together.
              </p>
            </div>
            <SocialIcon />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
