import React from 'react'

const Title = ({title, subtitle}) => {
  return (
    <div className='md:mb-5 mb-5'>
    <h2 className="md:text-5xl md:mb-4 text-3xl mb-2 font-bold">
        {title}
    </h2>
    {subtitle && <p className="md:text-xl text-md">{subtitle}</p>}
    </div>
  )
}

export default Title
